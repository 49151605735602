export const CHART_PADDING_HORIZONTAL = 50
export const CHART_PADDING_VERTICAL = 50

export const MINIMUM_CHART_PADDING_VERTICAL = 5
export const MINIMUM_CHART_PADDING_HORIZONTAL = 5

export const NODE_PADDING_VERTICAL = 8
export const NODE_PADDING_HORIZONTAL = 10

export const NODE_MARGIN_VERTICAL = 15
export const NODE_MARGIN_HORIZONTAL = 25
export const NODE_TEXT_FONTSIZE = 16

export const BRANCH_PADDING_HORIZONTAL = 10

export const NODE_BORDER_RADIUS = 5
export const ROOT_RADIUS = 10

export const TEXT_PADDING_VERTICAL = 5

export const QUANTIFIER_HEIGHT = 14
export const QUANTIFIER_ICON_WIDTH = 24
export const QUANTIFIER_ICON_HEIGHT = 16
export const QUANTIFIER_ICON_MARGIN_VERTICAL = 4
export const QUANTIFIER_ICON_MARGIN_RIGHT = 4
export const QUANTIFIER_TEXT_FONTSIZE = 12

export const NAME_TEXT_FONTSIZE = 12
export const NAME_HEIGHT = 10
