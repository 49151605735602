// GRAPH
export const GRAPH_TEXT_FONT_SIZE = 16
export const GRAPH_TEXT_LIEN_HEIGHT = GRAPH_TEXT_FONT_SIZE * 1.5
export const GRAPH_NODE_PADDING_VERTICAL = 4
export const GRAPH_NODE_PADDING_HORIZONTAL = 10
export const GRAPH_NODE_BORDER_RADIUS = 5
export const GRAPH_NODE_MARGIN_VERTICAL = 15
export const GRAPH_NODE_MARGIN_HORIZONTAL = 25
export const GRAPH_GROUP_NODE_PADDING_VERTICAL = 15
export const GRAPH_CHOICE_PADDING_HORIZONTAL = 25
